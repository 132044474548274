<!-- @format -->

<template>
	<div>
		<div>
			<div style="background-color: rgb(76, 92, 122)">
				<div class="table-header">{{ tableTitleByType }}</div>
			</div>
			<ve-table
				:columns="columns"
				:rows="rows"
				:onRowDoubleClick="onRowDoubleClick"
				:onCellClick="onCellClick"
			>
				<template slot="table-row" slot-scope="props">
					<span v-if="props.column.field === 'select'">
						<b-button class="is-small is-success">Select</b-button>
					</span>
					<span v-else>
						{{ props.formattedRow[props.column.field] }}
					</span>
				</template>
			</ve-table>
		</div>
		<div>
			<div id="customer" v-if="props.customerType === 'customer'">
				<div class="max-width-150">
					<modal-edit-customer v-if="isModalVisible" @close="closeModal" />
				</div>
				<div class="max-width-150">
					<modal-add-customer
						v-if="isModalAddCustomerVisible"
						@close="closeAddCustomerModal"
					/>
				</div>
			</div>
			<div
				id="potential-customer"
				v-if="props.customerType === 'potentialCustomer'"
			>
				<div class="max-width-150">
					<ModalEditPotentialCustomer
						v-if="isModalPotentialVisible"
						@close="closePotentialModal"
					/>
				</div>
				<div class="max-width-150">
					<ModalAddPotentialCustomer
						v-if="isModalAddPotentialCustomerVisible"
						@close="closeAddPotentialCustomerModal"
					/>
				</div>
			</div>
			<div class="max-width-150">
				<modal-add-customers-address
					v-if="isModalAddCustomersAddressVisible"
					@close="closeAddCustomersAddressModal"
				/>
			</div>
			<div
				style="
					background-color: rgb(76, 92, 122);
					padding-bottom: 30px;
					display: flex;
					justify-content: space-between;
				"
			>
				<div v-if="props.customerType === 'customer'">
					<b-button
						class="margin-top-30 margin-left-15 width-250"
						style="background-color: rgb(182, 90, 92); color: white"
						@click="addNewCustomer"
					>
						Add New Customer
					</b-button>
				</div>
				<div v-if="props.customerType === 'potentialCustomer'">
					<b-button
						class="margin-top-30 margin-left-15 width-250"
						style="background-color: rgb(61, 196, 134); color: white"
						@click="addPotentialCustomer"
					>
						Add potential customer
					</b-button>
				</div>
				<div>
					<download-csv :data="rows">
						<div class="margin-top-30 width-250" style="margin-right: 15px">
							<b-button type="is-danger" outlined>
								Download the Customers List
							</b-button>
						</div>
					</download-csv>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { onMounted, reactive, ref, computed } from '@vue/composition-api'
import { useQuery, useResult } from '@vue/apollo-composable'
import GetCustomerListQuery from '@/_srcv2/views/pages/admin-reports/customer-list/GetCustomerListQuery.gql'
import Store from '@/store'
import ModalEditCustomer from './ModalEditCustomer.vue'
import ModalAddCustomer from './ModalAddCustomer.vue'
import ModalEditPotentialCustomer from '@/_srcv2/pages/business-development/potential-customers/components/ModalEditPotentialCustomer.vue'
import ModalAddPotentialCustomer from '@/_srcv2/pages/business-development/potential-customers/components/ModalAddPotentialCustomer.vue'
import ModalAddCustomersAddress from './ModalAddCustomersAddress'
import useCustomerDetailsForm from '@/_srcv2/views/pages/admin-reports/customer-list/useCustomerDetailsForm.js'

export default {
	name: 'CustomerDetailsForm',
	components: {
		ModalEditCustomer,
		ModalAddCustomer,
		ModalEditPotentialCustomer,
		ModalAddPotentialCustomer,
		ModalAddCustomersAddress,
	},
	props: {
		customerType: {
			type: String,
			default: 'customer',
		},
	},
	setup(props) {
		const tableTitleByType = computed(() => {
			if (props.customerType === 'customer') {
				return 'Customers List (Filtered)'
			} else if (props.customerType === 'potentialCustomer') {
				return 'Potential Customers List (Filtered)'
			}
		})
		const {
			isModalVisible,
			isModalAddCustomerVisible,
			isModalPotentialVisible,
			isModalAddPotentialCustomerVisible,
			isModalAddCustomersAddressVisible,
			ourCompany,
			id,
			nickname,
			title,
			orgNum,
			discount,
			email,
			isActive,
			creditLimit,
			isRisky,
			reference,
			customerTerm,
			customerInvoiceRule,
			sendInvoiceBy,
			customerPriceClass,
			modelCompanies,
		} = useCustomerDetailsForm()
		const closeModal = () => (isModalVisible.value = false)
		const showModal = () => (isModalVisible.value = true)
		// todo ----------------------------------------------
		const closeModalPotential = () => (isModalPotentialVisible.value = false)
		const showModalPotential = () => (isModalPotentialVisible.value = true)
		// todo ----------------------------------------------
		const closeAddCustomerModal = () =>
			(isModalAddCustomerVisible.value = false)
		const showAddCustomerModal = () => (isModalAddCustomerVisible.value = true)
		// todo ----------------------------------------------
		const closeAddPotentialCustomerModal = () =>
			(isModalAddPotentialCustomerVisible.value = false)
		const showAddPotentialCustomerModal = () =>
			(isModalAddPotentialCustomerVisible.value = true)
		// todo ----------------------------------------------
		const closeAddCustomersAddressModal = () =>
			(isModalAddCustomersAddressVisible.value = false)
		const showAddCustomersAddressModal = () =>
			(isModalAddCustomersAddressVisible.value = true)
		// todo ----------------------------------------------
		onMounted(() => {
			getCustomers()
		})
		const options = reactive({
			fetchPolicy: 'no-cache',
		})
		const { result, refetch } = useQuery(
			GetCustomerListQuery,
			() => ({
				company_id: Store.getters.getUserCurrentCompany,
				customer_type: props.customerType,
			}),
			options,
		)
		const allActiveCustomers = useResult(result, null, (data) => data.customers)
		const getCustomers = () => {
			refetch()
				.then(() => fetchCustomers(allActiveCustomers))
				.then((customers) => objectToCSV(customers))
				.then((data) => {
					console.log('-------------------', data)
					csvFile.value = data
				})
		}
		const csvFile = ref({})
		const fetchCustomers = (data) => {
			return new Promise((resolve, reject) => {
				const customers = data.value.map((item) => {
					return {
						companyId: item.company_id,
						customerId: item.customer_id,
						customerTitle: item.customer_title,
						customerNickname: item.customer_nickname || '',
						customer: `${item.customer_title} - ${item.customer_nickname}`,
						customerOrgNumber: item.customer_org_num,
						customerEmail: item.customer_email || '',
						customerInvoiceRule: item.customer_invoice_rule,
						customerTermF: `${item.customer_term} days`,
						customerTerm: item.customer_term,
						customerPriceClassF: `Class ${item.customer_price_class}`,
						customerPriceClass: item.customer_price_class,
						customerReference: item.customer_reference || '',
						customerDiscount: item.customer_discount_percent,
						isActive: item.customer_is_active,
						isRisky: item.customer_is_risky,
						creditLimit: item.customer_credit_limit,
						sendInvoiceBy: item.send_invoice_by,
					}
				})
				if (data !== null || undefined) {
					rows.value = customers
					resolve(customers)
				} else {
					const reason = new Error('Data could not be fetched from database')
					reject(reason)
				}
			})
		}
		const objectToCSV = (data) => {
			const csvRows = []

			// get headers
			const headers = Object.keys(data[0])
			csvRows.push(headers.join(','))

			// loop over values
			for (const row of data) {
				const values = headers.map((header) => {
					const escaped = ('' + row[header]).replace(/"/g, '\\"')
					return `"${escaped}"`
				})
				csvRows.push(values.join(','))
			}

			// join and return all lines
			console.log('csv', csvRows.join('\n'))
			return csvRows.join('\n')
		}

		const download = (data) => {
			const anchor = document.createElement('a')
			anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(data)
			anchor.target = '_blank'
			anchor.download = 'nameYourFileHere.csv'
			anchor.click()
		}
		const rows = ref([])
		const columns = [
			{
				label: 'Customer Number',
				field: 'customerId',
				width: '135px',
			},
			{
				label: 'Customer',
				field: 'customer',
			},
			{
				label: 'Email',
				field: 'customerEmail',
				width: '115px',
			},
			{
				label: 'Invoice Rule',
				field: 'customerInvoiceRule',
				width: '85px',
			},
			{
				label: 'Term',
				field: 'customerTermF',
				width: '85px',
			},
			{
				label: 'Price Class',
				field: 'customerPriceClassF',
				width: '85px',
			},
			{
				label: 'Add Address',
				field: 'select',
				width: '105px',
			},
		]
		const getModalFormFieldValues = (row) => {
			return new Promise((resolve, reject) => {
				if (row !== null || undefined) {
					console.log('selected item ---***+++---*** ', row)
					console.log('modelCompanies', modelCompanies)
					ourCompany.value = row.companyId
					id.value = row.customerId
					nickname.value = row.customerNickname
					title.value = row.customerTitle
					orgNum.value = row.customerOrgNumber
					discount.value = row.customerDiscount
					email.value = row.customerEmail
					isActive.value = row.isActive
					creditLimit.value = row.creditLimit
					isRisky.value = row.isRisky
					reference.value = row.customerReference
					customerTerm.value = row.customerTerm
					customerInvoiceRule.value = row.customerInvoiceRule
					sendInvoiceBy.value = row.sendInvoiceBy
					customerPriceClass.value = row.customerPriceClass
					resolve(result)
				} else {
					const reason = new Error('Data could not be fetched from database')
					reject(reason)
				}
			})
		}
		const onRowDoubleClick = (params) => {
			getModalFormFieldValues(params.row).then(() => showModal())
		}
		const onCellClick = (params) => {
			if (params.column.field === 'select') {
				Store.dispatch('setAddressListCustomer', params.row.customerId)
				Store.dispatch(
					'setAddressListOurCompany',
					Store.getters.getUserCurrentCompany,
				)
				Store.dispatch(
					'setAddressListCustomerInfo',
					params.row.customerId + ' - ' + params.row.customer,
				)
				showAddCustomersAddressModal()
			}
		}
		const addNewCustomer = () => {
			showAddCustomerModal()
		}
		const addPotentialCustomer = () => {
			showAddPotentialCustomerModal()
		}
		return {
			tableTitleByType,
			isModalVisible,
			showModal,
			closeModal,
			showModalPotential,
			closeModalPotential,
			isModalAddCustomerVisible,
			showAddCustomerModal,
			closeAddCustomerModal,
			closeAddPotentialCustomerModal,
			isModalAddCustomersAddressVisible,
			showAddCustomersAddressModal,
			closeAddCustomersAddressModal,
			onRowDoubleClick,
			onCellClick,
			addNewCustomer,
			addPotentialCustomer,
			download,
			allActiveCustomers,
			objectToCSV,
			rows,
			columns,
			props,
			isModalPotentialVisible,
			isModalAddPotentialCustomerVisible,
		}
	},
}
</script>

<style scoped></style>
