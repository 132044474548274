<!-- @format -->

<template>
	<transition name="modal-fade">
		<div>
			<div class="modal-backdrop">
				<div
					class="modal"
					role="dialog"
					aria-labelledby="modalTitle"
					aria-describedby="modalDescription"
				>
					<header class="modal-header" id="modalTitle" style="margin-top: 60px">
						<slot name="header"></slot>
					</header>

					<section class="modal-body" id="modalDescription">
						<div class="margin-top-30">
							<set-customer-details />
						</div>
						<slot name="body"></slot>
						<div>
							<b-button
								type="button"
								class="is-danger"
								@click="onCloseEditForm"
								aria-label="Close modal"
							>
								X Close
							</b-button>
							<b-button
								type="button"
								style="margin-left: 30px"
								class="is-success"
								@click="onSaveEditForm"
							>
								Save customer details
							</b-button>
						</div>
					</section>

					<footer class="modal-footer">
						<slot name="footer"></slot>
					</footer>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import UpdateCustomerMutation from '@/_srcv2/views/pages/admin-reports/customer-list/UpdateCustomerMutation.graphql'
import useCustomerDetailsForm from '@/_srcv2/views/pages/admin-reports/customer-list/useCustomerDetailsForm.js'
import { onMounted, ref } from '@vue/composition-api'
import SetCustomerDetails from '@/_srcv2/views/pages/admin-reports/customer-list/SetCustomerDetails.vue'
import { useMutation } from '@vue/apollo-composable'

export default {
	name: 'ModalEditPotentialCustomer',
	components: { SetCustomerDetails },
	setup() {
		const {
			schemaCompanies,
			modelCompanies,
			formOptionsCompanies,
			isModalVisible,
			companiesIdentityDetailsReloadKey,
		} = useCustomerDetailsForm()
		// -------------------------------------------------------------------------
		onMounted(() => console.log('isModalVisible', isModalVisible.value))
		// ---------------------------------------------------------------------------
		const onCloseEditForm = () => {
			isModalVisible.value = false
		}
		// ----------------------------------------------------------------------------
		const setVariables = () => {
			return new Promise((resolve, reject) => {
				const condition = true
				if (condition) {
					companyId.value = modelCompanies.ourCompany
					customerId.value = modelCompanies.id
					// -----------------------------------------------------------------------------
					mutationInput.customer_credit_limit = modelCompanies.creditLimit
					mutationInput.customer_discount_percent = modelCompanies.discount
					mutationInput.customer_email = modelCompanies.email
					mutationInput.customer_invoice_rule =
						modelCompanies.customerInvoiceRule
					mutationInput.customer_is_active = modelCompanies.isActive
					mutationInput.customer_is_risky = modelCompanies.isRisky
					mutationInput.customer_nickname = modelCompanies.nickname
					mutationInput.customer_org_num = modelCompanies.orgNum
					mutationInput.customer_price_class = modelCompanies.customerPriceClass
					mutationInput.customer_reference = modelCompanies.reference
					mutationInput.customer_term = modelCompanies.customerTerm
					mutationInput.customer_title = modelCompanies.title
					mutationInput.send_invoice_by = modelCompanies.sendInvoiceBy
					resolve('ok')
				} else {
					const reason = new Error("Mutation Variabler couldn't set")
					reject(reason)
				}
			})
		}
		const companyId = ref('')
		const customerId = ref('')
		const mutationInput = {
			customer_credit_limit: '',
			customer_discount_percent: '',
			customer_email: '',
			customer_invoice_rule: '',
			customer_is_active: '',
			customer_is_risky: '',
			customer_nickname: '',
			customer_org_num: '',
			customer_price_class: '',
			customer_reference: '',
			customer_term: '',
			customer_title: '',
			send_invoice_by: '',
		}
		const { mutate: updateCustomer, onDone } = useMutation(
			UpdateCustomerMutation,
			() => ({
				variables: {
					company_id: companyId.value,
					customer_id: customerId.value,
					input: mutationInput,
				},
			}),
		)
		onDone(() => window.alert('Customer is updated!'))
		const onSaveEditForm = () => {
			setVariables()
				.then(() => {
					updateCustomer()
				})
				.then(() => {
					isModalVisible.value = false
					companiesIdentityDetailsReloadKey.value =
						companiesIdentityDetailsReloadKey.value + 1
					console.log('mutationInput', mutationInput)
				})
		}
		return {
			onCloseEditForm,
			onSaveEditForm,
			isModalVisible,
			// selectedAddress,
			schemaCompanies,
			modelCompanies,
			formOptionsCompanies,
		}
	},
}
</script>

<style scoped>
.modal-backdrop {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.9);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 100000000001;
}

.modal {
	background: #ffffff;
	box-shadow: 2px 2px 20px 1px;
	overflow-x: auto;
	display: flex;
	flex-direction: column;
	max-width: 700px;
	max-height: 100%;
	margin: auto;
	padding: 20px;
	opacity: 1;
	overflow-y: auto;
}

.modal-header,
.modal-footer {
	padding: 15px;
	display: flex;
}

.modal-header {
	position: relative;
	color: #4aae9b;
	justify-content: space-between;
}

.modal-footer {
	flex-direction: column;
	justify-content: flex-end;
}

.modal-body {
	position: relative;
	padding: 20px 10px;
}

.modal-fade-enter,
.modal-fade-leave-to {
	opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
	transition: opacity 0.5s ease;
}
</style>
