/** @format */

// To share the state between components write thw following three lines
// Otherwise each component creates a new instance of the reactive state object that can not sher the state
import Vue from 'vue'
import VueCompositionApi from '@vue/composition-api'
Vue.use(VueCompositionApi)

import { reactive, toRefs } from '@vue/composition-api'

const formState = reactive({
	isModalVisible: false,
	isModalAddCustomerVisible: false,
	isModalPotentialVisible: false,
	isModalAddPotentialCustomerVisible: false,
	isModalAddCustomersAddressVisible: false,
	companiesIdentityDetailsReloadKey: 1,
	showAddFields: false,
	isSaveCustomerDisabled: true,
})
const modelCompanies = reactive({
	ourCompany: '',
	id: '',
	nickname: '',
	title: '',
	orgNum: '',
	discount: 0,
	email: '',
	isActive: true,
	creditLimit: 0,
	isRisky: false,
	reference: '',
	customerTerm: 0,
	customerInvoiceRule: '',
	sendInvoiceBy: '',
	customerPriceClass: '',
})
const schemaCompanies = reactive({
	groups: [
		{
			legend: 'Company Details',
			id: 'details',
			featured: true,
			fields: [
				{
					type: 'cleave',
					cleaveOptions: {
						blocks: [3, 4, 4],
						uppercase: true,
					},
					min: 13,
					max: 13,
					label: 'Company ID (Customer or Supplier)',
					model: 'id',
					id: 'customer_id',
					readonly: true,
					featured: true,
					disabled: true,
					required: true,
					validator: ['string'],
					onValidated: function (model, errors) {
						if (errors.length > 0)
							console.log(
								'Validation error in Company ID field! Errors:',
								errors,
							)
					},
				},
				{
					type: 'input',
					inputType: 'text',
					label: 'Nickname',
					model: 'nickname',
					placeholder: 'Please enter Company nickname',
					min: 6,
					max: 45,
					readonly: false,
					featured: true,
					disabled: false,
					required: false,
					validator: ['string'],
					onValidated: function (model, errors) {
						if (errors.length > 0)
							console.log('Validation error in Name field! Errors:', errors)
					},
				},
				{
					type: 'input',
					inputType: 'text',
					label: 'Title',
					model: 'title',
					placeholder: 'Please enter Company title',
					min: 6,
					max: 60,
					readonly: false,
					featured: true,
					disabled: false,
					required: true,
					validator: ['string'],
					onValidated: function (model, errors) {
						if (errors.length > 0)
							console.log(
								'Validation error in Company Title field! Errors:',
								errors,
							)
					},
				},
				{
					type: 'input',
					inputType: 'text',
					label: 'Reference',
					model: 'reference',
					placeholder: 'Please enter Company reference',
					min: 6,
					max: 40,
					readonly: false,
					featured: true,
					disabled: false,
					required: false,
					validator: ['string'],
					onValidated: function (model, errors) {
						if (errors.length > 0)
							console.log(
								'Validation error in Company Reference field! Errors:',
								errors,
							)
					},
				},
				{
					type: 'cleave',
					cleaveOptions: {
						blocks: [6, 4],
						delimiter: '-',
						uppercase: true,
					},
					label: 'Org Num',
					model: 'orgNum',
					placeholder: 'Please enter Company Organization number',
					min: 11,
					max: 11,
					readonly: false,
					featured: true,
					disabled: false,
					required: true,
					validator: ['string'],
					onValidated: function (model, errors) {
						if (errors.length > 0)
							console.log(
								'Validation error in Org. Num. field! Errors:',
								errors,
							)
					},
				},
				{
					type: 'input',
					inputType: 'number',
					label: 'Customer Invoice Term(day(s))',
					model: 'customerTerm',
					hint: "Please enter customer's payment term in day(s)",
					readonly: false,
					featured: true,
					disabled: false,
					required: true,
					validator: ['integer'],
					onValidated: function (model, errors) {
						if (errors.length > 0)
							console.log(
								"Validation error in Customer's Term field! Errors:",
								errors,
							)
					},
				},
				{
					type: 'select',
					label: 'Customer Invoice Rule',
					model: 'customerInvoiceRule',
					hint: "Please enter customer's sale-invoice-sales-inland Rule",
					values: ['daily', 'monthly'],
					featured: true,
					required: true,
					validator: ['string'],
					onValidated: function (model, errors) {
						if (errors.length > 0)
							console.log(
								"Validation error in Customer's Invoice Rule field! Errors:",
								errors,
							)
					},
				},
				{
					type: 'select',
					label: 'Send Invoice by',
					model: 'sendInvoiceBy',
					hint: "Please enter customer's sale-invoice delivery method",
					values: ['by e-mail', 'by post', 'by delivery'],
					featured: true,
					required: true,
					validator: ['string'],
					onValidated: function (model, errors) {
						if (errors.length > 0)
							console.log(
								"Validation error in Customer's Invoice Delivery Method field! Errors:",
								errors,
							)
					},
				},
				{
					type: 'select',
					label: 'Customer Price Class',
					model: 'customerPriceClass',
					hint: "Please enter customer's price class",
					values: ['a', 'b', 'c', 'd', 's', 'hra', 'hrb', 'hrc', 'hrd', 'z'],
					featured: true,
					required: true,
					validator: ['string'],
					onValidated: function (model, errors) {
						if (errors.length > 0)
							console.log(
								'Validation error in Customer Price Class field! Errors:',
								errors,
							)
					},
				},
				{
					type: 'input',
					inputType: 'number',
					label: 'Discount(%)',
					model: 'discount',
					placeholder: 'Please enter discount %',
					readonly: false,
					featured: true,
					disabled: false,
					required: true,
					validator: ['integer'],
					onValidated: function (model, errors) {
						if (errors.length > 0)
							console.log(
								'Validation error in Company Discount field! Errors:',
								errors,
							)
					},
				},
				{
					type: 'input',
					inputType: 'email',
					label: 'Email',
					model: 'email',
					placeholder: "Company's e-mail address",
					validator: ['email'],
					onValidated: function (model, errors) {
						if (errors.length > 0)
							console.log(
								'Validation error in Company Email field! Errors:',
								errors,
							)
					},
				},
				{
					type: 'cleave',
					label: 'Credit Limit',
					model: 'creditLimit',
					placeholder: 'Please enter Credit Limit',
					featured: true,
					required: true,
					validator: ['positivenumber', 'required'],
					cleaveOptions: {
						numeral: true,
						numeralThousandsGroupStyle: 'thousand',
						numeralDecimalScale: 2,
						numeralDecimalMark: ',',
					},
					onValidated: function (model, errors) {
						if (errors.length > 0)
							console.log(
								'Validation error in Credit Limit field! Errors:',
								errors,
							)
					},
				},
			],
		},
		{
			legend: 'Company Settings',
			fields: [
				{
					type: 'checkbox',
					label: 'Active',
					model: 'isActive',
					hint: 'Check the box if the item is active / uncheck the box if the item is inactive',
					featured: true,
					required: true,
					default: true,
					onValidated: function (model, errors) {
						if (errors.length > 0)
							console.log(
								'Validation error in is Active field! Errors:',
								errors,
							)
					},
				},
				{
					type: 'checkbox',
					label: 'Risky',
					model: 'isRisky',
					hint: 'Check the box if the item is RISKY / uncheck the box if the item is NOT RISKY',
					featured: true,
					required: true,
					default: true,
					onValidated: function (model, errors) {
						if (errors.length > 0)
							console.log('Validation error in is Risky field! Errors:', errors)
					},
				},
			],
		},
	],
})
const formOptionsCompanies = reactive({
	validateAfterLoad: false,
	validateAfterChanged: true,
	validateAsync: true,
	fieldIdPrefix: 'companies',
})
export default function useCustomerDetailsForm() {
	return {
		schemaCompanies,
		modelCompanies,
		formOptionsCompanies,
		...toRefs(modelCompanies),
		...toRefs(formState),
	}
}
