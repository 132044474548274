import Vue from 'vue'
import VueCompositionApi from '@vue/composition-api'
Vue.use(VueCompositionApi)

import { reactive, toRefs } from '@vue/composition-api'

const showState = reactive({
	addEditToggle: '',
	addressToggle: false,
	selectedCompanyId: '',
	ourCompanyId: '',
})
export default function useCompaniesIdentityDetailsState() {
	return {
		...toRefs(showState),
	}
}
