<template>
	<div>
		<div v-if="!showAddFields" class="margin-bottom-30">
			<get-customer-number />
		</div>
		<div v-if="!!showAddFields">
			<form id="set-customer-details">
				<vue-form-generator
					@validated="onFormValidated"
					:schema="schemaCompanies"
					:model="modelCompanies"
					:options="formOptionsCompanies"
				>
				</vue-form-generator>
			</form>
		</div>
	</div>
</template>

<script>
import useCustomerDetailsForm from '@/_srcv2/views/pages/admin-reports/customer-list/useCustomerDetailsForm.js'
import GetCustomerNumber from '@/_srcv2/views/pages/admin-reports/customer-list/GetCustomerNumber.vue'
// Cleave
// eslint-disable-next-line no-unused-vars
import cleave from 'cleave.js'
import { onMounted } from '@vue/composition-api'

export default {
	name: 'AddCustomerDetails',
	components: { GetCustomerNumber },
	setup() {
		const {
			schemaCompanies,
			modelCompanies,
			formOptionsCompanies,
			showAddFields,
			isSaveCustomerDisabled,
		} = useCustomerDetailsForm()
		onMounted(() => {
			showAddFields.value = false
		})
		const onFormValidated = () => {
			isSaveCustomerDisabled.value = false
		}
		return {
			showAddFields,
			schemaCompanies,
			modelCompanies,
			formOptionsCompanies,
			onFormValidated,
		}
	},
}
</script>

<style scoped></style>
