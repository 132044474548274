import { render, staticRenderFns } from "./GetCustomerNumber.vue?vue&type=template&id=62c23247&scoped=true&"
import script from "./GetCustomerNumber.vue?vue&type=script&lang=js&"
export * from "./GetCustomerNumber.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62c23247",
  null
  
)

export default component.exports