<template>
	<div>
		<form id="form">
			<vue-form-generator
				:schema="schemaCompanyId"
				:model="modelCompanyId"
				:options="formOptionsCompanyId"
			>
			</vue-form-generator>
		</form>
		<div>
			<b-button
				class="is-primary"
				type="submit"
				@click="createCompanyId"
				:disabled="!!disableAddButton"
				>Get new Company Number
			</b-button>
		</div>
	</div>
</template>

<script>
import { reactive, ref, onMounted } from '@vue/composition-api'
import Store from '@/store'
import { useMutation, useQuery } from '@vue/apollo-composable'
import GetCompanyIdQuery from '@/_srcv2/graphql/customer/queries/getCompanyID.query.gql'
import UpdateCidSecondGroupMutation from '@/_srcv2/graphql/customer/mutations/updateCidSecondGroup.mutation.gql'
import useCustomerDetailsForm from '@/_srcv2/views/pages/admin-reports/customer-list/useCustomerDetailsForm.js'

export default {
	name: 'GetCustomerNumber',
	setup() {
		onMounted(() => {
			disableAddButton.value = false
			schemaCompanyId.groups[0].fields[0].disabled = false
		})
		const { showAddFields, modelCompanies } = useCustomerDetailsForm()
		// Create Company ID ***********************************************************************
		const options = reactive({
			fetchPolicy: 'no-cache',
		})
		const { result, refetch } = useQuery(
			GetCompanyIdQuery,
			() => ({
				our_company: Store.getters.getUserCurrentCompany,
			}),
			options,
		)
		const { mutate: updateCidSecondGroup } = useMutation(
			UpdateCidSecondGroupMutation,
			() => ({
				variables: {
					our_company: modelCompanyId.ourCompany,
					cid_second_group: cidSecondGroup.value,
				},
			}),
		)
		// Get the state from composable **************************************
		const cidFirstGroup = ref('')
		const cidSecondGroup = ref('')
		const disableAddButton = ref(false)
		// Promise ****************************************************************
		const getId = () => {
			return new Promise((resolve, reject) => {
				if (result) {
					cidFirstGroup.value = result.value.companies[0].cid_first_group
					cidSecondGroup.value = result.value.companies[0].cid_second_group + 1
					console.log('getId is fired')
					resolve('Id is fetched')
				} else {
					const reason = new Error('Id could not be fetched')
					reject(reason)
				}
			})
		}
		const mergeId = () => {
			return new Promise((resolve, reject) => {
				if (result) {
					let cid = `${modelCompanyId.idLetterCode} ${cidFirstGroup.value} ${cidSecondGroup.value}`
					console.log('mergeId is fired')
					resolve(cid)
				} else {
					const reason = new Error('Data could not be fetched')
					reject(reason)
				}
			})
		}
		const setIdAndShow = (data) => {
			return new Promise((resolve, reject) => {
				if (data) {
					disableAddButton.value = true
					schemaCompanyId.groups[0].fields[0].disabled = true
					showAddFields.value = true
					console.log('setIdAndShow is fired', data)
					setInitialValues(data)
					resolve(data)
				} else {
					const reason = new Error('Company Number could not be saved')
					reject(reason)
				}
			})
		}
		// ------------------------------------------------------------------------------------
		const setInitialValues = (customerNumber) => {
			modelCompanies.ourCompany = Store.getters.getUserCurrentCompany
			modelCompanies.id = customerNumber
			modelCompanies.nickname = undefined
			modelCompanies.title = undefined
			modelCompanies.orgNum = undefined
			modelCompanies.discount = 0
			modelCompanies.email = undefined
			modelCompanies.isActive = true
			modelCompanies.creditLimit = 0
			modelCompanies.isRisky = false
			modelCompanies.reference = undefined
			modelCompanies.customerTerm = 10
			modelCompanies.customerInvoiceRule = 'daily'
			modelCompanies.sendInvoiceBy = 'by delivery'
			modelCompanies.customerPriceClass = 'c'
		}
		// ------------------------------------------------------------------------------------
		const createCompanyId = () => {
			refetch()
				.then(() => getId())
				.then(() => updateCidSecondGroup())
				.then(() => mergeId())
				.then((data) => setIdAndShow(data))
				.then((result) => {
					console.log('result', result)
				})
		}
		// Create form ****************************************************************
		const modelCompanyId = reactive({
			ourCompany: Store.getters.getUserCurrentCompany,
			idLetterCode: 'SE0',
		})
		const schemaCompanyId = reactive({
			groups: [
				{
					legend: 'Company Details',
					id: 'details',
					featured: true,
					fields: [
						{
							type: 'cleave',
							cleaveOptions: {
								blocks: [3],
								uppercase: true,
							},
							label: 'Company Letter Code',
							model: 'idLetterCode',
							placeholder: 'Please enter Company Letter Code',
							min: 3,
							max: 3,
							readonly: false,
							featured: true,
							disabled: false,
							required: true,
							validator: ['string'],
							default: 'SE0',
							onValidated: function (model, errors) {
								if (errors.length > 0)
									console.log(
										'Validation error in Company Letter Code field! Errors:',
										errors,
									)
							},
						},
					],
				},
			],
		})
		const formOptionsCompanyId = reactive({
			validateAfterLoad: false,
			validateAfterChanged: true,
			validateAsync: true,
			fieldIdPrefix: 'companies',
		})
		return {
			modelCompanyId,
			schemaCompanyId,
			formOptionsCompanyId,
			createCompanyId,
			disableAddButton,
		}
	},
}
</script>

<style scoped></style>
